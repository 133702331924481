import React from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO/SEO"

import Layout from "../components/layout"
import Container from "../components/container/container"

const SitemapPage = () => (
  <Layout>
    <SEO
      title="Sitemap"
      desc="Sitemap"
      canonical="https://tr069.cloud/sitemap/"
      pathname="/sitemap"
      />
    <section>
      <Container>
        <h1>
          Sitemap
        </h1>
        <ul className="sitemap">
          <li>
            <Link to="/">
              Cloud services for managing, monitoring and testing devices (CWMP/TR-069, USP/TR-369)
            </Link>
          </li>
          <li>
            <Link to="articles/clarification-of-certain-terms-and-explanation-of-information-security-aspects">
              Clarification of certain terms and explanation of information security aspects
            </Link>
          </li>
          <li>
            <Link to="articles/the-user-services-platform-tr-369">
              Successive evolution of TR-069 - the User Services Platform (USP, TR-369)
            </Link>
          </li>
          <li>
            <Link to="articles/specification-of-technical-report-069">
              Specification of Technical Report 069
            </Link>
          </li>
          <li>
            <Link to="articles/the-technical-report-069">
              The Technical Report 069
            </Link>
          </li>
          <li>
            <Link to="sitemap">
              Sitemap
            </Link>
          </li>
          <li>
            <Link to="privacy-policy">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </Container>
    </section>
  </Layout>
)

export default SitemapPage
